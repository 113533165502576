import * as React from "react"
import styled, { ThemeProvider } from "styled-components"

const theme = {
  breakpoints: {
    xs: "0px",
    sm: "370px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1600px",
  },
}

const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>Under maintenence</Wrapper>
    </ThemeProvider>
  )
}

export default IndexPage

const Wrapper = styled.div`
  padding:40px;
`